import React from 'react'
import config from '../config';
import ajaxcall from '../utils/ajaxcall';

function State(props) {
    const [State, setState] = React.useState(null);
    React.useEffect(() => {
        async function fetchData() {
            let apiCall = await ajaxcall.getCall(`${config.api.generic}/state`, {}, props.token);
            if(apiCall && apiCall.status) {
                setState(apiCall.context.data);
            }
        }
        fetchData();
    }, []); 

    const handleChange=((val)=>{ 
        props.updateChangeEvent(val.target.value, props.field) 
    })

    if (!State) return <div className="text-primary">Loading state values</div>;
    return (
        <>
            <select {...props.register("stateId", { required: true })} disabled={props.readOnly} value={props.field.value} className="form-control custom-select" onChange={handleChange} >
                <option value=""> Choose...</option>
                {(State) ? State.map((element) => { 
                    return (<option key={Math.random()} value={element.id}>{element.label}</option>)
                }) : ''}
            </select>
            <small className="form-text text-danger">
                {props.errors.stateId?.type === "required" && "State is mandatory"}
            </small>
        </>
    )
}
export default State;