// libs
import React, {useEffect, useState, useContext} from 'react';
import Pagination from "react-js-pagination";
// components
import { ContextStore } from '../../providers/context';
import Breadcrumb from "../../components/breadcrumb";
import Loading from '../../components/loading';
import Record404 from '../../components/record404';
import TableBrowseProducts from './tables/browse-products';
import SearchFilters from '../../components/search-filters';
// css 
import "../../assets/css/style.css";
// config & functions
import utils from '../../utils';
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';

function BrowseProducts({orgInfo, setloadmaincomponent, loadmaincomponent, token}) {
    // context variable
	const globalState = useContext(ContextStore);
	// context variable --//
    
    const [ItemList, setItemList] = useState({});
    const [IsLoading, setIsLoading] = useState(true);
	const [Is404, setIs404] = useState(false);
    const [CurrentPaginationId, setCurrentPaginationId] = useState(0);
    
    const [AppliedSearchKeyword, setAppliedSearchKeyword] = useState("");
    const [AppliedCategory, setAppliedCategory] = useState("");

    const industryId = (orgInfo && orgInfo.hasOwnProperty('industryId')) ? orgInfo.industryId : '';
    const getHelpers = (globalState.hasOwnProperty('state') && globalState.state.hasOwnProperty('helpers')) ? globalState.state.helpers : null;
    const navLoading =  (getHelpers && getHelpers.hasOwnProperty('navLoading')) ? getHelpers.navLoading : false;
    const phoneNoVerified = (getHelpers && getHelpers.hasOwnProperty('phoneNoVerified')) ? getHelpers.phoneNoVerified : false;
    const isUserContactInfoAvailable = (getHelpers && getHelpers.hasOwnProperty('isUserContactInfoAvailable')) ? getHelpers.isUserContactInfoAvailable : false;

	const goNext = async (page) => {
		let uc = await utils.updateComponent(loadmaincomponent, {
			name: page,
			status: true
		});
		setloadmaincomponent(uc); // upate component
	}

    const loadApiData = async (params) => {
        params.requestMode = "client";
        setIsLoading(true);
        let apiData = await ajaxCall.getCall(`${config.api.generic}/products`, params, token);
        if(apiData && apiData.status) {
            setItemList({list: apiData.context.data.records, totalItems: apiData.context.data.totalCount});
            setIsLoading(false);
            setIs404(false);
        } else {
            setItemList({list: [], totalItems: 0});
            setIsLoading(false);
            setIs404(true);
        }
    }

    const handlePageChange = async (pageNumber) => {
        setIsLoading(true);
		let offset = (pageNumber*config.pagination.limitPerPage)-config.pagination.limitPerPage;
        loadApiData({showSelected:false, categoryId:AppliedCategory, searchKeyword:AppliedSearchKeyword, offset, limit:config.pagination.limitPerPage});
        setCurrentPaginationId(pageNumber);
	}

    const removeSearchKeyword = () => {
        setAppliedSearchKeyword("");
    }

    useEffect(() => {
        async function fetchData() {
            if(!Is404 && Object.keys(ItemList).length === 0) {
			    loadApiData({showSelected:false, categoryId:AppliedCategory, searchKeyword:AppliedSearchKeyword, offset:CurrentPaginationId, limit:config.pagination.limitPerPage});
            } else {
                setIsLoading(false);
            }
		}
		fetchData();
    }, []);

    useEffect(() => {
        setCurrentPaginationId(0);
        loadApiData({showSelected:false, categoryId:AppliedCategory, searchKeyword:AppliedSearchKeyword, offset:0, limit:config.pagination.limitPerPage});
    }, [AppliedSearchKeyword, AppliedCategory]);

    console.log(globalState.state)

	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col">
						<div className="mt-3">
							<h3 className="fs-20rem text-center rwt-txt-dark-blue mb-0">Browse Menu</h3>
							<div className='d-flex'>
								<Breadcrumb list={[{label: 'Home', url: `/catering/${orgInfo.domain}`}, {label: 'Browse Menu', url: false}]} />
							</div>
						</div>
					</div>
				</div>
				<div className="row">
                    <div className="col-md-10 offset-md-1 ">
                        <SearchFilters industryid={orgInfo.industryId} token={token} orgfoodtype={orgInfo.foodTypeId} setappliedsearchkeyword={setAppliedSearchKeyword} appliedsearchkeyword={AppliedSearchKeyword} setappliedcategory={setAppliedCategory} appliedcategory={AppliedCategory} />
                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            {(AppliedSearchKeyword !== "") ? <button type='button' className='mt-3 mb-2 btn py-1 px-2 btn-md fs-13rem text-dark bg-light border border-1 border-secondary rounded'>{AppliedSearchKeyword} <span onClick={removeSearchKeyword}>X</span></button> : null}
                        </div>
                    </div>
					<div className="col-md-10 offset-md-1 ">
						<div className="pt-2 pb-2">
                            <div>{(IsLoading) ? <Loading /> : (Is404) ? <Record404 /> : <TableBrowseProducts domain={orgInfo.domain} list={ItemList.list}  />}</div>
						</div>
					</div>
                    <div className="col-12">
                        <div className="d-flex justify-content-center mb-5">
                            <Pagination
                                activePage={CurrentPaginationId}
                                itemsCountPerPage={config.pagination.limitPerPage}
                                totalItemsCount={parseInt(ItemList.totalItems)}
                                pageRangeDisplayed={5}
                                onChange={(e) => handlePageChange(e)}
                                innerClass="pagination mb-5" 
                                itemClass="page-item" 
                                linkClass="page-link" 
                                activeClass="active"
                            />
                        </div>
                    </div>  
				</div>
			</div>
            {(!navLoading && parseInt(industryId) === 2 && phoneNoVerified)?<button type="button" className="rwt-secondary-btn text-end btn-lg text-white btn btn-outline-none fixed-to-bottom" style={{zIndex: '1054'}} onClick={() => goNext(isUserContactInfoAvailable?'MenuSelection':'UserContact')}>Add Menu</button>:null}
		</>
    )
}

export default BrowseProducts;