// libs
import React, {useEffect, useRef, useState, useContext} from 'react';
import { ContextStore } from '../providers/context';
// components 
// ... 
// config & functions 
// ...

function ConfirmationBox({setdeleteitem, deleteitem}) {
    // context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//

    const wrapperRef = useRef(null);
    const [IsDelete, setIsDelete] = useState(false);

    const initDeleteRecord = async () => {
        setIsDelete(true);
        dispatch({type: 'deleteMenuItem', payload: deleteitem.id});
        setdeleteitem({id: null, status: false});
    }

    return (
        <div className="modal fade show" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.6)'}}>
			<div className="modal-dialog">
				<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title" id="staticBackdropLabel">Confirmation Box</h5>
					<button type="button" className="btn-close" onClick={() => setdeleteitem({id: null, status: false})} aria-label="Close"></button>
				</div>
				<div className="modal-body">
					Are you sure you want to delete this record?
				</div>
				<div className="modal-footer">
					<button type="button" className="btn btn-secondary" onClick={() => setdeleteitem({id: null, status: false})}>Close</button>
					<button type="button" className="btn btn-primary" disabled={IsDelete} onClick={() => initDeleteRecord()}>{(IsDelete)?'Deleting...':'Delete'}</button>
				</div>
				</div>
			</div>
		</div>
    )
}
export default ConfirmationBox;
