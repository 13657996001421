// libs
import React, {useState, useContext} from 'react';
import { ToastContainer, toast } from 'react-toastify';
// components 
import { ContextStore } from '../../../providers/context';
import Preview from '../preview';
import LoadingBox from '../../../components/loading-box';
// config & functions 
import config from '../../../config';
import ajaxCall from '../../../utils/ajaxcall';

function TableQuotationList({listdata, gonext, orgInfo, token}) {
    // context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//

    const [ShowLoadingBox, setShowLoadingBox] = useState(false);
    const [ShowPreview, setShowPreview] = useState(false);
    const [PreviewData, setPreviewData] = useState([]);

    const editQuote = (index) => {
        let eq = {
            referenceName: listdata[index].reference_name,
            startDate: new Date(listdata[index].start_date),
            endDate: new Date(listdata[index].end_date),
            quotationId: listdata[index].quotation_id,
            menuItems: JSON.parse(listdata[index].menu_items),
            status: listdata[index].quotation_status_id,
        }
        dispatch({type: 'editNewQuotation', payload: eq});
        gonext('MenuSelection')
    }

    const generatePreview = async (qId) => {
        setShowLoadingBox(true);
        let apiData = await ajaxCall.getCall(`${config.api.generic}/static/pdf`, {quotationId: qId}, token);
        if(apiData && apiData.status && apiData.context.hasOwnProperty('data')) {
            if(Object.keys(apiData.context.data).length > 0) {
                setPreviewData(apiData.context.data);
                setShowLoadingBox(false);
                setShowPreview(true);
            } else {
                toast.error('Something went wrong. Please try again');
                setShowLoadingBox(false);
            }
        } else {
            toast.error('Something went wrong. Please try again');
            setShowLoadingBox(false);
        }
    }

    if(listdata && Array.isArray(listdata) && listdata.length > 0) {
        return <>
            <div className="table-responsive mb-4">
                <table className="table table-striped fs-14rem">
                    <thead>
                        <tr>
                            <th>Reference Name</th>
                            <th>Last update by</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listdata.map((data, index) => {
                            return <tr key={Math.random()}>
                                <td style={{minWidth: '200px'}}>{data.reference_name}</td>
                                <td style={{minWidth: '320px'}}><span className='fw-bold'>{data.generated_by}</span> on {data.date_time}</td>
                                <td style={{minWidth: '120px'}}>{(parseInt(data.quotation_status_id) === 1)?<span className='text-success fw-bold'>Approved</span>:<span className='text-secondary fw-bold'>Not Approved</span>}</td>
                                <td style={{minWidth: '120px'}}>
                                    <button type='button' onClick={() => generatePreview(data.quotation_id)} className='me-2 btn btn-sm rwt-primary-btn text-white'>View</button> 
                                    {(parseInt(data.quotation_status_id) === 1)?'No permission to edit':<button className='btn btn-sm btn-warning text-dark' onClick={() => editQuote(index)}>Edit</button>}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {(ShowPreview)? <Preview data={PreviewData} domain={orgInfo.domain} setshowpreview={setShowPreview} />:null}
            {(ShowLoadingBox)? <LoadingBox />:null}
        </>
    } else {
        return null;
    }
}

export default TableQuotationList;