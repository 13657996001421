// libs
import React, {useEffect, useState, useContext} from 'react';
// components
import { ContextStore } from '../../providers/context';
import Breadcrumb from "../../components/breadcrumb";
// css 
import "../../assets/css/style.css";
// config & functions
import utils from '../../utils';

function ThankYouNote({orgInfo, setloadmaincomponent, loadmaincomponent}) {
    // context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//

    const clientName = (globalState.hasOwnProperty('state') && globalState.state.hasOwnProperty('userContactInfo')) && globalState.state.userContactInfo.hasOwnProperty('firstName') ? globalState.state.userContactInfo.firstName : "";
    
	const goNext = async (page) => {
		let uc = await utils.updateComponent(loadmaincomponent, {
			name: page,
			status: true
		});
		setloadmaincomponent(uc); // upate component
	}

    const createNew = () => {
        dispatch({type: 'resetEditQuotation', payload: ""});
        goNext('MenuSelection');
    }
    
	const viewQuotation = () => {
        dispatch({type: 'resetEditQuotation', payload: ""});
        goNext('QuotationList');
    }

	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col">
						<div className="mt-3">
							<h3 className="fs-20rem text-center rwt-txt-dark-blue mb-0">Menu Created</h3>
							<div className='d-flex'>
								<Breadcrumb list={[{label: 'Home', url: `/catering/${orgInfo.domain}`}, {label: 'Menu Created', url: false}]} />
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-10 offset-md-1 ">
						<div className="py-3">
                            <div>
                                <p className="fs-14rem">Dear {clientName},</p>
                                <p className="fs-14rem">We have received your updated menu list. Thank you for choosing our service. We'll make sure to serve you the best in industry.</p>
                                <p className="fs-14rem">Our team will get in touch with you soon.</p>
                            </div>
                            <div className="text-center my-3">
								<button type="button" className="me-2 fs-14rem rwt-primary-btn btn-lg text-white btn" onClick={viewQuotation}>View Menu</button>
                                <button type="button" className="fs-14rem rwt-primary-btn btn-lg text-white btn" onClick={createNew}>Create New</button>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</>
    )
}

export default ThankYouNote;