import React from 'react'
import config from '../config';
import ajaxcall from '../utils/ajaxcall';

function City(props) {
    const [City, setCity] = React.useState(null);
    React.useEffect(() => {
        async function fetchData() {
            let apiCall = await ajaxcall.getCall(`${config.api.generic}/city`, {}, props.token);
            if(apiCall && apiCall.status) {
                setCity(apiCall.context.data);
            }
        }
        fetchData();
    }, []); 

    const handleChange=((val)=>{ 
        props.updateChangeEvent(val.target.value, props.field) 
    })

    if (!City) return <div className="text-primary">Loading city values</div>;
    return (
        <>
            <select {...props.register("cityId", { required: true })} disabled={props.readOnly} value={props.field.value} className="form-control custom-select" onChange={handleChange} >
                <option value=""> Choose...</option>
                {(City) ? City.map((element) => { 
                    return (<option key={Math.random()} value={element.id}>{element.label}</option>)
                }) : ''}
            </select>
            <small className="form-text text-danger">
                {props.errors.cityId?.type === "required" && "City is mandatory"}
            </small>
        </>
    )
}
export default City;