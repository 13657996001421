// libs
import React from 'react'
// components 
import Record404 from './record404';
// config & functions 
// ...

function ImagePreviewer({previewimage, setpreviewimage}) {
    return (
        <div className="modal fade show" data-dismiss="modal" tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.6)'}}>
			<div className="modal-dialog">
				<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title" id="staticBackdropLabel">Preview</h5>
					<button type="button" className="btn-close" onClick={()=>setpreviewimage('')} aria-label="Close"></button>
				</div>
				<div className="modal-body p-0" style={{maxHeight: '400px'}}>
					{(previewimage)? <img src={previewimage} width={'100%'} maxheight={'100%'} /> : <Record404 />}
				</div>
				<div className="modal-footer">
					<button type="button" className="btn btn-secondary fs-14rem" onClick={()=>setpreviewimage('')}>Close</button>
				</div>
				</div>
			</div>
		</div>
    )
}
export default ImagePreviewer;