// libs
import React, {useEffect, useState, useContext} from 'react';
import { ContextStore } from '../../providers/context';
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
// components
import Breadcrumb from "../../components/breadcrumb";
import City from '../../components/city';
import State from '../../components/state';
// css 
import "../../assets/css/style.css";
// config & functions
import utils from '../../utils';

function UserContact({orgInfo, setloadmaincomponent, loadmaincomponent, token}) {
	// context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//

	const inputFields = globalState.state.userContactInfo;
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

	const onSubmit = async (data) => {
		setDisableSubmitButton(true);
		// onSubmit, copy data to state objects...
		let fields = inputFields;
		for (const key in fields) {
			if (fields.hasOwnProperty(key) && data.hasOwnProperty(key)) {
				fields[key] = data[key];
			}
		}
		fields.isDataAvailable = true;
		dispatch({type: 'updateUserContactInfo', payload: fields});
		goNext('MenuSelection');
    }

	const updateChangeEvent = (value, field) => {
		field.onChange(value);
	}

	const goNext = async (page) => {
		let uc = await utils.updateComponent(loadmaincomponent, {
			name: page,
			status: true
		});
		setloadmaincomponent(uc); // upate component
	}

	return (
		<div className="container">
			<div className="row">
				<div className="col">
					<div className="mt-3">
						<h3 className="fs-20rem text-center rwt-txt-dark-blue mb-0">Basic Information</h3>
						<div className='d-flex'>
							<Breadcrumb list={[{label: 'Home', url: `/catering/${orgInfo.domain}`}, {label: 'Basic Information', url: false}]} />
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-10 offset-md-1 ">
					<div className="py-3 mb-5">
						<div className="login-form shadow shadow-intensity-md rounded-3 p-4 mb-5">
							<form action="" method="" className="g-3 p-md-5 p-sm-1" onSubmit={handleSubmit(onSubmit)}>
								<div className="row">
									<div className="col-md-12">
										{(parseInt(inputFields.statusId)===3)?<div className="text-left bg-warning text-dark fs-13rem p-2">
											You don't have Edit permission to update your records. Please contact your admin for write access
										</div>:<div className="text-left bg-info text-dark fs-13rem p-2">Please update your contact details before proceeding next</div>}
									</div>
									<div className="col-md-12">
										<div className="text-left mt-3 rwt-txt-dark-blue">
											<h3><b>Contact Details</b></h3>
										</div>
									</div>
									<div className="col-md-6 mt-3">
										<label className="form-label fs-14rem">First Name</label>
										<input type="text" readOnly={parseInt(inputFields.statusId)===3?true:false} defaultValue={inputFields && inputFields.firstName || ''} className="form-control" name="firstName" {...register("firstName", { required: {
											value: true,
											message: "First name is mandatory"
										}, minLength: {
											value: 2,
											message: "Enter atleast 2 characters"
										}, maxLength: {
											value: 25,
											message: "Only 25 characters allowed"
										} })} />
										<small className="form-text text-danger">
											{errors.firstName && errors.firstName.message}
										</small>
									</div>
									<div className="col-md-6 mt-3">
										<label className="form-label fs-14rem">Last Name</label>
										<input type="text" readOnly={parseInt(inputFields.statusId)===3?true:false} defaultValue={inputFields && inputFields.lastName || ''} className="form-control" name="lastName" {...register("lastName", { required: {
											value: true,
											message: "Last name is mandatory"
										}, minLength: {
											value: 1,
											message: "Enter atleast 1 characters"
										}, maxLength: {
											value: 25,
											message: "Only 25 characters allowed"
										} })} />
										<small className="form-text text-danger">
											{errors.lastName && errors.lastName.message}
										</small>
									</div>
									<div className="col-md-6 mt-3">
										<label className="form-label fs-14rem">Phone Number</label>
										<input type="text" readOnly={true} defaultValue={inputFields && inputFields.phoneNo || ''} className="form-control" name="phoneNo" {...register("phoneNo", { required: {
											value: true,
											message: "Phone no is mandatory"
										}, minLength: {
											value: 10,
											message: "Minimum 10 characters allowed"
										}, maxLength: {
											value: 10,
											message: "Max 10 characters allowed"
										}, pattern: {
											value: /^[0-9+-]+$/,
											message: "Enter valid mobile phone!"
										} })} />
										<small className="form-text text-danger">
											{errors.phoneNo && errors.phoneNo.message}
										</small>
									</div>
									<div className="col-md-6 mt-3">
										<label className="form-label fs-14rem">Email</label>
										<input type="text" readOnly={parseInt(inputFields.statusId)===3?true:false} defaultValue={inputFields && inputFields.email || ''} className="form-control" name="email" {...register("email", { required: false, pattern: {
											value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
											message: "Invalid email address"
										} })} />
										<small className="form-text text-danger">
											{errors.email && errors.email.message}
										</small>
									</div>
									<div className="col-md-12 mt-3">
										<label className="form-label fs-14rem">Address (Event location)</label>
										<textarea readOnly={parseInt(inputFields.statusId)===3?true:false} defaultValue={inputFields && inputFields.address || ''} className="form-control" name="address" {...register("address", { required: {
											value: true,
											message: "Address is mandatory"
										}, minLength: {
											value: 5,
											message: "Minimum 5 characters allowed"
										}, maxLength: {
											value: 250,
											message: "Max 250 characters allowed"
										}  })}></textarea>
										<small className="form-text text-danger">
											{errors.address && errors.address.message}
										</small>
									</div>
									<div className="col-md-4 mt-3">
										<label className="form-label fs-14rem">State</label>
										<Controller
											name='stateId' 
											defaultValue={inputFields && inputFields.stateId || ''}
											rules={{required: true}}
											control={control} 
											render={({field}) => (
												<State register={register} errors={errors} readOnly={parseInt(inputFields.statusId)===3?true:false} updateChangeEvent={updateChangeEvent} field={field} token={token} />
											)}
										/>
									</div>
									<div className="col-md-4 mt-3">
										<label className="form-label fs-14rem">City</label>
										<Controller
											name='cityId' 
											defaultValue={inputFields && inputFields.cityId || ''}
											rules={{required: true}}
											control={control} 
											render={({field}) => (
												<City register={register} errors={errors} readOnly={parseInt(inputFields.statusId)===3?true:false} updateChangeEvent={updateChangeEvent} field={field} token={token} />
											)}
										/>
									</div>
									<div className="col-md-4 mt-3">
										<label className="form-label fs-14rem">Pincode</label>
										<input type="text" readOnly={parseInt(inputFields.statusId)===3?true:false} defaultValue={inputFields.pincode || ''} className="form-control" name="pincode" {...register("pincode", { required: {
											value: true,
											message: "Pincode is mandatory"
										}, minLength: {
											value: 6,
											message: "Minimum 6 characters allowed"
										}, maxLength: {
											value: 6,
											message: "Max 6 characters allowed"
										}, pattern: {
											value: /^[0-9+-]+$/,
											message: "Enter valid Pincode!"
										} })} />
										<small className="form-text text-danger">
											{errors.pincode && errors.pincode.message}
										</small>
									</div>
									<div className="col-12 text-end">
										<button type="button" className="rwt-primary-btn text-end btn-lg text-white btn mt-4 me-3 fs-14rem" onClick={() => goNext('BrowseProducts')}>Back</button>
										<button type="submit" disabled={DisableSubmitButton} className="rwt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{(DisableSubmitButton)?`LOADING...`:`NEXT`}</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default UserContact;