// libs
import React, {useEffect, useState, useContext} from 'react';
import Pagination from "react-js-pagination";
// components
import { ContextStore } from '../../providers/context';
import Breadcrumb from "../../components/breadcrumb";
import Loading from '../../components/loading';
import Record404 from '../../components/record404';
import TableQuotationList from './tables/quotation-list';
// css 
import "../../assets/css/style.css";
// config & functions
import utils from '../../utils';
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';

function QuotationList({orgInfo, setloadmaincomponent, loadmaincomponent, token}) {
    // context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//
    
    const [ListData, setListData] = useState([]);
	const [TotalListCount, setTotalListCount] = useState(0);
	const [IsLoading, setIsLoading] = useState(true);
	const [Is404, setIs404] = useState(false);
	const [ActivePage, setActivePage] = useState(1);
    const userContactInfo = (globalState && globalState.hasOwnProperty('state') && globalState.state.hasOwnProperty('userContactInfo')) ? globalState.state.userContactInfo : {}; 
    const phoneNo = userContactInfo.hasOwnProperty('phoneNo') ? userContactInfo.phoneNo : '';
	const goNext = async (page) => {
		let uc = await utils.updateComponent(loadmaincomponent, {
			name: page,
			status: true
		});
		setloadmaincomponent(uc); // upate component
	}

    useEffect(() => {
        async function fetchData() {
            loadApiData({phoneNo, offset:0, limit: config.pagination.limitPerPage}); // This function also called in confirmation-box.js to reload data after delete
		}
		fetchData();
    }, []);

    const loadApiData = async (params) => {
        let apiData = await ajaxCall.getCall(`${config.api.generic}/public/quotation`, params, token);
        if(apiData && apiData.status) {
            setListData(apiData.context.data.records);
            setTotalListCount(apiData.context.data.totalCount);
            setIsLoading(false);
        } else {
            setIsLoading(false);
			setIs404(true);
        }
    }

    const handlePageChange = async (pageNumber) => {
		setActivePage(pageNumber);
		let offset = (pageNumber*config.pagination.limitPerPage)-config.pagination.limitPerPage;
		loadApiData({phoneNo, offset, limit: config.pagination.limitPerPage})
	}

    const newQuote = () => {
        dispatch({type: 'resetEditQuotation'});
        goNext('MenuSelection')
    }

	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col">
						<div className="mt-3">
							<h3 className="fs-20rem text-center rwt-txt-dark-blue mb-0">Quotation List</h3>
							<div className='d-flex'>
								<Breadcrumb list={[{label: 'Home', url: `/catering/${orgInfo.domain}`}, {label: 'Quotation List', url: false}]} />
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-10 offset-md-1 ">
						<div className="pt-3">
                            <div className="login-form shadow shadow-intensity-md rounded-3 p-2 mb-5">
								<div className="g-3 p-md-5 p-sm-1">
                                    <div className="row">
                                        <div className='col'>
                                            <div className='text-end pb-2'>
                                                <button className='btn btn-sm rwt-primary-btn text-white' onClick={() => newQuote()}>Create new quotation</button>
                                            </div>
                                        </div>
                                    </div>
                                    {(IsLoading) ? <Loading /> : (Is404) ? <Record404 /> : <TableQuotationList listdata={ListData} gonext={goNext} orgInfo={orgInfo} token={token} />}
                                </div>
                            </div>
						</div>
					</div>
                    <div className="col-12">
                        <div className="d-flex justify-content-center mb-5">
                            <Pagination
                                activePage={ActivePage}
								itemsCountPerPage={config.pagination.limitPerPage}
								totalItemsCount={parseInt(TotalListCount)}
								pageRangeDisplayed={5}
								onChange={(e) => handlePageChange(e)}
								innerClass="pagination" 
								itemClass="page-item" 
								linkClass="page-link" 
								activeClass="active"
                            />
                        </div>
                    </div>  
				</div>
			</div>
            <button type="button" className="rwt-secondary-btn text-end btn-lg text-white btn btn-outline-none fixed-to-bottom" style={{zIndex: '1054'}} onClick={() => goNext('UserContact')}>Add Menu</button>
		</>
    )
}

export default QuotationList;