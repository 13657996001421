// libs
import React from 'react'
// components 
// ...
// config & functions 
import config from '../config';
import utils from '../utils';

function Logo({orgInfo, setloadmaincomponent, loadmaincomponent}) {

    const goNext = async (page) => {
		let uc = await utils.updateComponent(loadmaincomponent, {
			name: page,
			status: true
		});
		setloadmaincomponent(uc); // upate component
	}

    return (
        <div>
            <button onClick={() => goNext('HomePage')} className="navbar-brand border-0 bg-transparent"> <img src={`${config.assets.products}/${orgInfo.domain}/products/thumbnail/${orgInfo.logo}`} alt="" style={{maxHeight: '40px', maxWidth: '120px'}} /></button>
        </div>
    )
}
export default Logo;
