// libs
import React, {useState, useEffect, useContext} from 'react'
// components
import {ContextStore} from '../../providers/context'; 
import Loading from "../../components/loading";
import Domain404 from "../../components/domain404";
// css 
import "../../assets/css/style.css";
import "../../assets/css/core.css";
// config & functions
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';
import utils from '../../utils';

function HomePage({orgInfo, setloadmaincomponent, loadmaincomponent, token}) {
	// context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//

	const inputFields = globalState.state.homePageInfo;
	const [IsLoading, setIsLoading] = useState(false);
	const [DomainNotFound, setDomainNotFound] = useState(false);

    const goNext = async (page) => {
		let uc = await utils.updateComponent(loadmaincomponent, {
			name: page,
			status: true
		});
		setloadmaincomponent(uc); // upate component
	}

    const ShowDetails = () => {
        return <div>
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {(inputFields.banners && inputFields.banners.length > 0)?inputFields.banners.map((ele, i) => {
                        return <button key={Math.random()} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={i} className={(i===0)?`active`:``} aria-current={(i===0)?`true`:`false`} aria-label={`Slide ${i}`}></button>
                    }):null}
                </div>
                <div className="carousel-inner">
                    {(inputFields.banners && inputFields.banners.length > 0)?inputFields.banners.map((ele, i) => {
                        return <div className={`carousel-item ${i===0?` active `:``}`} key={Math.random()}>
                            <img src={`${config.assets.products}/${orgInfo.domain}/products/medium/${ele.image}`} className="d-block w-100" alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                            <h5>{ele.title}</h5>
                            <p>{ele.subTitle}</p>
                            </div>
                        </div>
                    }):null}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className='d-flex flex-column justify-content-center pt-5 pb-3'>
                <div className='fs-24rem fw-bold text-uppercase text-center'>{inputFields.intro?.title}</div>
                <div className='fs-18rem text-center'>{inputFields.intro?.subTitle}</div>
                <div className='fs-16rem text-secondary px-3 text-justify'>{inputFields.intro?.description}</div>
            </div>
            <div className='text-center'>
                <button type="button" className="rwt-secondary-btn btn-lg text-white btn btn-outline-none mb-5" onClick={() => goNext('BrowseProducts')}>Browse Menu</button>
            </div>
        </div>
    }

    // Validate phone no on page reload/refresh...
    const validatePhNo = async () => {
		const getPhNo = utils.getCookie('phno');
		if(getPhNo) {
			// Get the phone no from cookie, if data available push it to context else do nothing
			let apiData = await ajaxCall.getCall(`${config.api.generic}/validate/phone`, {
				phoneNo: getPhNo
			}, token);
			if(apiData && apiData.status) {
				const records = apiData.context.data;
				// client info...
				if(Array.isArray(records) && records.length > 0) {
					let contactInfo = {
						clientId: records[0].id, 
						firstName: records[0].first_name,
						lastName: records[0].last_name,
						phoneNo: getPhNo,
						email: records[0].email,
						address: records[0].address,
						stateId: records[0].state_id,
						cityId: records[0].city_id,
						pincode: records[0].pincode,
						statusId: records[0].status_id
					};
					dispatch({type: 'updateUserContactInfo', payload: contactInfo});
                    dispatch({type: 'updateHelpers', payload: [
                        {key: 'phoneNoVerified', value: true},
                        {key: 'isUserContactInfoAvailable', value: true},
                        {key: 'navLoading', value: false}
                    ]});
				}
			} else {
                dispatch({type: 'updateHelpers', payload: [
                    {key: 'phoneNoVerified', value: true},
                    {key: 'isUserContactInfoAvailable', value: false},
                    {key: 'navLoading', value: false}
                ]});
            }
		} else {
            // Enable nav regardless of user info...
            dispatch({type: 'updateHelpers', payload: [
                {key: 'navLoading', value: false}
            ]});
        }
	}

    useEffect(() => {
		async function fetchData() {
			setIsLoading(true);
			let apiData = await ajaxCall.getCall(`${config.api.generic}/public/account/homepage`, '', token);
            if(apiData && apiData.status) {
                let resp = JSON.parse(apiData.context.data[0].homepage_config);
                let bConfig = resp.banners;
                let banners = [];
                // Remove empty image config 
                bConfig.map(ele => {
                    if(ele.image !== "") {
                        banners.push(ele);
                    }
                })
                resp.banners = banners;
				dispatch({type: 'updateHomePageInfo', payload: resp});
				setIsLoading(false);
				setDomainNotFound(false);
            } else {
				setIsLoading(false);
				setDomainNotFound(true);
			}
		}
		if(inputFields && Object.keys(inputFields).length === 0) {
			fetchData();
		}
        validatePhNo();
	}, []);

	console.log(inputFields)

	return (
		<>{(IsLoading)? <Loading /> : (DomainNotFound)? <Domain404 /> : <ShowDetails />}</>
	)
}

export default HomePage;