import axios from "axios";
import { ErrorHandler } from "./error-handler";

const getCall = async (url, paramData='', token='', addOnHeader = {}) => {
    let tokenHeader = {
        "X-Token": `${token}` 
    }
    let resp = await axios.get(url, {
        params: paramData,
        headers: Object.assign(tokenHeader, addOnHeader)
    })
    .then((res) => {
        return {status: true, context: res};
    })
    .catch((err) => {
        return ErrorHandler(err);
    })

    return resp;
}

const postCall = async (url, paramData='', token='', addOnHeader = {}) => {
    let tokenHeader = {
        "X-Token": `${token}` 
    }
    let resp = await axios.post(url, paramData, {
        headers: Object.assign(tokenHeader, addOnHeader)
    })
    .then((res) => {
        return {status: true, context: res};
    })
    .catch((err) => {
        return ErrorHandler(err);
    })

    return resp;
}

const patchCall = async (url, paramData='', token='') => {
    let resp = await axios.patch(url, paramData, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "X-Token": `${token}` 
        }
    })
    .then((res) => {
        return {status: true, context: res};
    })
    .catch((err) => {
        return ErrorHandler(err);
    })

    return resp;
}

const deleteCall = async (url, paramData='', token='') => {
    let resp = await axios.delete(url, {
        params: paramData,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "X-Token": `${token}` 
        }
    })
    .then((res) => {
        return {status: true, context: res};
    })
    .catch((err) => {
        return ErrorHandler(err);
    })

    return resp;
}

export default {
    getCall, 
    postCall,
    patchCall,
    deleteCall
}