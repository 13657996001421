// libs
import React, {useEffect, useState, useContext} from 'react';
import { ContextStore } from '../../providers/context';
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// components
import Breadcrumb from "../../components/breadcrumb";
import AddMenu from './add-menu';
import SelectionGrid from './selection-grid';
import ConfirmationBox from '../../components/confirmation-box';
import LoadingBox from '../../components/loading-box';
// css 
import "../../assets/css/style.css";
// config & functions
import utils from '../../utils';
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';

function MenuSelection({orgInfo, setloadmaincomponent, loadmaincomponent, token}) {
	// context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//

    const [ReferenceName, setReferenceName] = useState('');
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [IsSubmitted, setIsSubmitted] = useState(false);
	const [LoadMenu, setLoadMenu] = useState(false);
	const [DeleteItem, setDeleteItem] = useState({id: null, status: false});
	const [EditMenuValue, setEditMenuValue] = useState({});
	const [isUnsavedChanges, setisUnsavedChanges] = useState(false);
	const editQuotation = (globalState.hasOwnProperty('state') && globalState.state.hasOwnProperty('editQuotation')) ? globalState.state.editQuotation : {};
    const allMenuItems = (editQuotation.hasOwnProperty('menuItems')) ? editQuotation.menuItems : [];

	const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

	const onSubmit = async () => {
		setDisableSubmitButton(true);
		const userContactInfo = globalState.state.userContactInfo;		
		let quotation = globalState.state.editQuotation;

		let emptySelection = true;
		// verify once and ignore if empty menu selection
		if(quotation.menuItems.length > 0) {
			emptySelection = false;
			quotation.menuItems = JSON.stringify(quotation.menuItems);
			quotation.clientId = userContactInfo.clientId || '';
			quotation.phoneNo = userContactInfo.phoneNo;
		} 
		if(emptySelection) {
			toast.error("Please choose your items before submitting");
			setDisableSubmitButton(false);
		} else {
			setIsSubmitted(true);
			if(parseInt(userContactInfo.statusId) === 1) {
				// Save contact details 
				let saveContact = await ajaxCall.postCall(`${config.api.generic}/public/clients`, userContactInfo, token);
				if(saveContact && saveContact.status) {
					// Save quotation details
					let saveQuotation = await ajaxCall.postCall(`${config.api.generic}/public/quotation`, quotation, token);
					if(saveQuotation && saveQuotation.status) {
						toast.success("Records updated successfully");
						setDisableSubmitButton(false);
						setIsSubmitted(false);
						goNext('ThankYouNote');
					} else {
						toast.error("Oops!! Sorry something went wrong with request. Please try again");
						setDisableSubmitButton(false);
						setIsSubmitted(false);
					}
				} else {
					toast.error("Oops!! Sorry something went wrong with request. Please try again");
					setDisableSubmitButton(false);
					setIsSubmitted(false);
				}
			} else {
				// Save quotation details directly
				let saveQuotation = await ajaxCall.postCall(`${config.api.generic}/public/quotation`, quotation, token);
				if(saveQuotation && saveQuotation.status) {
					toast.success("Records updated successfully");
					setDisableSubmitButton(false);
					setIsSubmitted(false);
					goNext('ThankYouNote');
				} else {
					toast.error("Oops!! Sorry something went wrong with request. Please try again");
					setDisableSubmitButton(false);
					setIsSubmitted(false);
				}
			}
		}
    }

	const goNext = async (page) => {
		let uc = await utils.updateComponent(loadmaincomponent, {
			name: page,
			status: true
		});
		setloadmaincomponent(uc); // upate component
	}

	const handleVenueItems = (val) => {
		// if EditMenuValue is 0, then add as new record else update current record
		if(typeof EditMenuValue === 'object' && Object.keys(EditMenuValue).length === 0) {
			let config = {
				startDate: val.startDate,
				time: val.time,
				selectedItems: val.selectedItems,
				totalMembers: val.totalMembers
			}
			setLoadMenu(false);
			setEditMenuValue({});
			dispatch({type: 'addNewMenuItem', payload: config});
		} else {
			setLoadMenu(false);
			val.objId = EditMenuValue.objId;
			setEditMenuValue({});
			dispatch({type: 'updateMenuItem', payload: val});
		}
	}

	const editItem = (val) => {
		const data = allMenuItems[val];
		if(data) {
			data.objId = val;
			setEditMenuValue(data)
			setLoadMenu(true);
		}
	}

	const updateChangeEvent = (value, field) => {
        setisUnsavedChanges(true);
		if(field.name === "startDate")
			dispatch({type: 'upadteGlobalStartDate', payload: value});
		if(field.name === "endDate")
			dispatch({type: 'upadteGlobalEndDate', payload: value});
        field.onChange(value);
	}

	const newQuote = () => {
        dispatch({type: 'resetEditQuotation'});
        goNext('MenuSelection')
    }

	let globalStartDate = new Date();
	if(Object.keys(editQuotation).length > 0 && editQuotation.hasOwnProperty('startDate')) {
		globalStartDate = new Date(editQuotation.startDate);
	}

	let globalEndDate = new Date();
	if(Object.keys(editQuotation).length > 0 && editQuotation.hasOwnProperty('endDate')) {
		globalEndDate = new Date(editQuotation.endDate);
	}

	useEffect(() => {
        const timeout = setTimeout(() => {
			if(ReferenceName)
				dispatch({type: 'upadteReferenceName', payload: ReferenceName});
        }, 2000);
    
        // if this effect run again, because `value` changed, we remove the previous timeout
        return () => clearTimeout(timeout)
    }, [ReferenceName])

	console.log("all state......")
	console.log(globalState.state)

	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col">
						<div className="mt-3">
							<h3 className="fs-20rem text-center rwt-txt-dark-blue mb-0">Menu Selection</h3>
							<div className='d-flex'>
								<Breadcrumb list={[{label: 'Home', url: `/catering/${orgInfo.domain}`}, {label: 'Menu Selection', url: false}]} />
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-10 offset-md-1 ">
						<div className="py-3 mb-5">
							<div className="login-form shadow shadow-intensity-md rounded-3 p-2 mb-5">
								<form action="" method="" className="g-3 p-md-5 p-sm-1" onSubmit={handleSubmit(onSubmit)}>
									<div className="row">
										<div className='col'>
											<div className='text-end pb-2'>
												<button className='me-2 btn btn-sm rwt-primary-btn text-white' onClick={() => newQuote()}>Create new quotation</button>
												<button className='btn btn-sm rwt-primary-btn text-white' onClick={() => goNext('QuotationList')}>View all quotations</button>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-lg-6 col-md-12 col-sm-12 col-12 mt-3">
											<label className="form-label fs-14rem">Reference/Event Name</label>
											<input type="text" placeholder='Birthday, Marriage, etc' readOnly={parseInt(editQuotation.status)===3?true:false} defaultValue={editQuotation.referenceName || ''} className="form-control" name="referenceName" {...register("referenceName", { required: {
												value: true,
												message: "Reference/Event name is mandatory"
											}, minLength: {
												value: 2,
												message: "Minimum 2 characters allowed"
											}, maxLength: {
												value: 24,
												message: "Max 24 characters allowed"
											}, onChange:(e) => setReferenceName(e.target.value) })} />
											<small className="form-text text-danger">
												{errors.referenceName && errors.referenceName.message}
											</small>
										</div>
										<div className="col-lg-3 col-md-6 col-sm-6 col-6 mt-3">
											<label className="form-label fs-14rem">Event Start Date</label>
											<Controller
												name='startDate' 
												defaultValue={globalStartDate}
												rules={{required: true}}
												control={control} 
												render={({field}) => (
													<DatePicker 
														readOnly={parseInt(editQuotation.statusId)===3?true:false} 
														selected={field.value} 
														placeholderText="Start Date" 
														className="form-control" 
														format="MM-dd-y" 
														onChange={(e) => {
															updateChangeEvent(e, field)
														}}
													/>
												)}
											/>	
											<small className="form-text text-danger">
												{errors.startDate?.type === "required" && "Start Date is mandatory"}
											</small>
										</div>
										<div className="col-lg-3 col-md-6 col-sm-6 col-6 mt-3">
											<label className="form-label fs-14rem">Event End Date</label>
											<Controller
												name='endDate' 
												defaultValue={globalEndDate}
												rules={{required: true}}
												control={control} 
												format="MM-dd-y"  
												render={({field}) => (
													<DatePicker 
														readOnly={parseInt(editQuotation.statusId)===3?true:false} 
														selected={field.value} 
														placeholderText="Start Date" 
														className="form-control" 
														onChange={(e) => {
															updateChangeEvent(e, field)
														}}
													/>
												)}
											/>
											<small className="form-text text-danger">
												{errors.endDate?.type === "required" && "End Date is mandatory"}
											</small>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											{(allMenuItems && Array.isArray(allMenuItems) && allMenuItems.length > 0) ? <SelectionGrid list={allMenuItems} setdeleteitem={setDeleteItem} edititemfn={editItem} /> : null}
										</div>
										<div className="col-12">
											<div className="text-center mt-5 mb-5">
												<button type="button" className="rounded-circle border btn rwt-primary-btn fs-22rem" onClick={() => setLoadMenu(true)} style={{width: '65px', height: '65px'}}>+</button>
												<div className="text-center text-dark fw-bold">Add Menu</div>
											</div>
										</div>	
										<div className="col-12"><hr /></div>
										<div className="col-12 text-end">
											<button type="button" className="rwt-primary-btn text-end btn-lg text-white btn fs-14rem" onClick={() => goNext('UserContact')}>Back</button>
											{(Array.isArray(allMenuItems) && allMenuItems.length > 0) ? <button type="submit" disabled={DisableSubmitButton} className="rwt-secondary-btn text-end btn-lg text-white btn fs-14rem ms-3">{(DisableSubmitButton)?`LOADING...`:`SUBMIT`}</button> : null}
										</div> 
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			{(LoadMenu)? <AddMenu industryid={orgInfo.industryId} globaldate={{startDate: globalStartDate, endDate: globalEndDate}} handlevenueitemsfn={handleVenueItems} editmenuvalue={EditMenuValue} setloadmenu={setLoadMenu} orgfoodtype={orgInfo.foodTypeId} domain={orgInfo.domain} token={token} />:null}
			{(DeleteItem.status)? <ConfirmationBox setdeleteitem={setDeleteItem} deleteitem={DeleteItem} />:null}
			{(IsSubmitted)? <LoadingBox />:null}
			<ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
		</>
    )
}

export default MenuSelection;