import React from 'react'

function Breadcrumb({list}) {
    return (
        <nav aria-label="breadcrumb" className="my-1 m-auto">
            <ol className="breadcrumb">
                {(list && Array.isArray(list) && list.length > 0) ? list.map(data => {
                    return <li key={Math.random()} className="breadcrumb-item fs-14rem">{(data.url) ? <a href={data.url}>{data.label}</a> : data.label}</li>
                }): null}
            </ol>
        </nav>
    )
}
export default Breadcrumb;
