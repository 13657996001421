// libs
import React, {useState} from 'react';
// components 
import ImagePreviewer from '../../../components/image-previewer';
// config & functions 
import config from '../../../config';

function TableBrowseProducts({domain, list}) {

    const [PreviewImage, setPreviewImage] = useState('');

    if(list && Array.isArray(list) && list.length > 0) {
        return <>
            <div className="table-responsive mb-4">
                <table className="table table-striped fs-14rem">
                    <tbody>
                        {list.map((data, index) => {
                            return <tr key={Math.random()}>
                                <td width="50px">{(data.image)? <img src={`${config.assets.products}/${domain}/products/thumbnail/${data.image}`} width="44px" height="44px" onClick={() => setPreviewImage(`${config.assets.products}/${domain}/products/medium/${data.image}`)} className="rounded-circle border border-2 cursor-pointer" /> : '-'}</td>
                                <td className='p-0'>
                                    <div className='d-flex justify-content-between align-items-center flex-row h-100' style={{minHeight: '60px'}}>
                                        <div className='d-flex flex-column h-100 py-2'>
                                            <div className="fw-bold">{data.name} {(typeof data.tag_name !== null && data.tag_name)?<span className="rounded-pill py-1 px-2 fs-11rem fw-bold bg-danger text-white">{data.tag_name}</span>:null}</div>
                                            <div className="text-secondary fs-13rem">{data.description}</div>
                                        </div>
                                        {(parseInt(data.mrp))?<div className='h-100 rwt-bg-blue d-flex flex-column ms-2 justify-content-center px-2 text-start' style={{minHeight: '50px', minWidth: '80px'}}>
                                            {(parseInt(data.price_after_discount) > 0 && parseInt(data.price_after_discount)!==parseInt(data.mrp))?<>
                                                <span className='text-white fs-12rem fw-bold'><strike>Rs {data.mrp}/-</strike></span>
                                                <span className='text-white fs-14rem fw-bold'>Rs {data.price_after_discount}/-</span>
                                            </>:<span className='text-white fs-14rem fw-bold'>Rs {data.mrp}/-</span>}
                                        </div>:null}
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            {(PreviewImage)? <ImagePreviewer previewimage={PreviewImage} setpreviewimage={setPreviewImage} />:null}
        </>
    } else {
        return null;
    }
}

export default TableBrowseProducts;