// libs
import React, {useEffect, useState, useContext} from 'react';
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
// components
import { ContextStore } from '../../providers/context';
import Breadcrumb from "../../components/breadcrumb";
// css 
import "../../assets/css/style.css";
// config & functions
import utils from '../../utils';
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';

function ValidatePhoneNo({orgInfo, setloadmaincomponent, loadmaincomponent, token}) {
    // context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//
    
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

	const goNext = async (page) => {
		let uc = await utils.updateComponent(loadmaincomponent, {
			name: page,
			status: true
		});
		setloadmaincomponent(uc); // upate component
	}

    const onSubmit = async (data) => {
        setDisableSubmitButton(true);
        let apiData = await ajaxCall.getCall(`${config.api.generic}/validate/phone`, {
            phoneNo: data.phoneNo
        }, token);
        if(apiData && apiData.status) {
            utils.setCookie('phno', data.phoneNo);
            const records = apiData.context.data;
            // client info...
            if(Array.isArray(records) && records.length > 0) {
                let contactInfo = {
                    clientId: records[0].id, 
                    firstName: records[0].first_name,
                    lastName: records[0].last_name,
                    phoneNo: data.phoneNo,
                    email: records[0].email,
                    address: records[0].address,
                    stateId: records[0].state_id,
                    cityId: records[0].city_id,
                    pincode: records[0].pincode,
                    statusId: records[0].status_id,
                    isDataAvailable: true
                };
                dispatch({type: 'updateUserContactInfo', payload: contactInfo});
                dispatch({type: 'updateHelpers', payload: [
                    {key: 'phoneNoVerified', value: true},
                    {key: 'isUserContactInfoAvailable', value: true},
                    {key: 'navLoading', value: false}
                ]});
            }
            setDisableSubmitButton(false);
            // default push empty values to access default values in next page
            dispatch({type: 'resetEditQuotation'});
            goNext('BrowseProducts');
        } else {
            toast.error(apiData.context.message);
            // Disabling this below code temporary till SMS integration... 
            // if(apiData.context.status === 403) {
            //     toast.error(apiData.context.message);
            // } else if(apiData.context.status === 404) {
            //     utils.setCookie('phno', data.phoneNo);
            //     // Push only phoneNo and statusId
            //     let contactInfo = {
            //         clientId: "", 
            //         firstName: "",
            //         lastName: "",
            //         phoneNo: data.phoneNo,
            //         email: "",
            //         address: "",
            //         stateId: "",
            //         cityId: "",
            //         pincode: "",
            //         statusId: 1,
            //         isDataAvailable: false
            //     };
            //     dispatch({type: 'updateUserContactInfo', payload: contactInfo});
            //     dispatch({type: 'updateHelpers', payload: [
            //         {key: 'phoneNoVerified', value: true},
            //         {key: 'isUserContactInfoAvailable', value: false},
            //         {key: 'navLoading', value: false}
            //     ]});
            //     // default push empty values to access default values in next page
            //     dispatch({type: 'resetEditQuotation'});
            //     goNext('BrowseProducts');
            // } else {
            //     toast.error("Opps! something went wrong. Please try again");
            // }
            setDisableSubmitButton(false);
        }
    }

	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col">
						<div className="mt-3">
							<h3 className="fs-20rem text-center rwt-txt-dark-blue mb-0">Validate Phone No</h3>
							<div className='d-flex'>
								<Breadcrumb list={[{label: 'Home', url: `/catering/${orgInfo.domain}`}, {label: 'Validate Phone No', url: false}]} />
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-10 offset-md-1 ">
						<div className="pt-3 pb-2">
                            <div className="login-form shadow shadow-intensity-md rounded-3 p-4 mb-5">
                                <form action="" method="" className="g-3 p-md-5 p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="text-left mt-3 rwt-txt-dark-blue">
                                                <h3><b>Phone No</b></h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-xl-6 col-12 mt-3">
                                            <label className="form-label fs-14rem">Registered Phone No</label>
                                            <div className="input-group mb-2">
                                                <input type="text" maxLength={10} className="form-control" placeholder="Phone No" aria-label="Phone No" aria-describedby="button-submit" {...register("phoneNo", { required: {
                                                    value: true,
                                                    message: "Phone no is mandatory"
                                                }, minLength: {
                                                    value: 10,
                                                    message: "Minimum 10 characters allowed"
                                                }, maxLength: {
                                                    value: 10,
                                                    message: "Max 10 characters allowed"
                                                }, pattern: {
                                                    value: /^[0-9+-]+$/,
                                                    message: "Enter valid phone no!"
                                                } })} />
                                                <button disabled={DisableSubmitButton} className="btn rwt-primary-btn text-white" type="submit" id="button-submit">{(DisableSubmitButton)?`LOADING...`:`SUBMIT`}</button>
                                            </div>
                                            <small className="form-text text-danger">
                                                {errors.phoneNo && errors.phoneNo.message}
                                            </small>
                                        </div>
                                    </div>
                                </form>
                            </div>
						</div>
					</div>
				</div>
			</div>
            <ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
		</>
    )
}

export default ValidatePhoneNo;