// libs
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
// components 
// ...
// config & functions 
import utils from '../../utils';

function SelectionGrid({list, setdeleteitem, edititemfn}) {
    
    const FormatedAMPM = ({date}) => {
        return utils.getYYMMDD_AMPM(date)
    }
    
    const deleteItem = (id) => {
        setdeleteitem({id: id, status: true})
    }
    return (<div className='row'>
        {list.map((data, i) => {
            return <div key={Math.random()} className='col-xs-12 col-sm-6 col-md-6 col-lg-4'>
                <div className='shadow-sm mb-3 mt-3 border border-1'>
                    <div className='p-2'>
                        <div className='fw-bold fs-16rem'><FormatedAMPM date={data.startDate} /></div>
                        <div className='fw-normal fs-14rem'>Total members: {data.totalMembers || 0}</div>
                        <div className='fw-normal fs-14rem'>Items selected: {data.selectedItems.length || 0}</div>
                    </div>
                    <div className='d-flex'>
                        <button type='button' className='bg-white border-0 border-top border-end flex-fill rwt-txt-blue p-2 fs-4rem fw-bold text-center' onClick={e=>edititemfn(i)}><FontAwesomeIcon icon={faEdit} className="" /> Edit</button>
                        <button type='button' className='bg-white border-0 border-top border-end flex-fill text-danger p-2 fs-4rem fw-bold text-center' onClick={e=>deleteItem(i)}><FontAwesomeIcon icon={faTrash} className="" /> Delete</button>
                    </div>
                </div>
            </div>
        })}
    </div>)
}
export default SelectionGrid;
