// libs
import React, {useState, useEffect, useContext} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faExternalLink, faMailReply, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
// components
import {ContextStore} from '../../providers/context' 
import Breadcrumb from '../../components/breadcrumb';
import Loading from "../../components/loading";
import Domain404 from "../../components/domain404";
// css 
import "../../assets/css/style.css";
import "../../assets/css/core.css";
// config & functions
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';
import utils from '../../utils';

function OrgContact({token, orgInfo}) {
	// context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//

	const inputFields = globalState.state.orgContactInfo;
	const [IsLoading, setIsLoading] = useState(false);
	const [DomainNotFound, setDomainNotFound] = useState(false);

	const ShowDetails = () => {
		return <div className="login-form shadow shadow-intensity-md rounded-3 p-4 mb-5">
			<div className='row'>
				<div className='col-md-6'>
					<div className='flex-column d-flex pt-2'>
						<div className='fs-20rem fw-bold'>{inputFields.name}</div>
						<div className='fs-16rem'>{inputFields.address}</div>
						<div className='fs-16rem'>{inputFields.state_label}, {inputFields.city_label}, {inputFields.pincode}</div>
						<div className='fs-16rem p-1 mt-1'><FontAwesomeIcon icon={faPhone} className="fs-20rem me-2" /> {inputFields.phone_no}</div>
						<div className='fs-16rem p-1'><FontAwesomeIcon icon={faMailReply} className="fs-20rem me-2" /> {inputFields.email}</div>
						{inputFields.website?<div className='fs-16rem p-1 mb-1'><FontAwesomeIcon icon={faExternalLink} className="fs-20rem me-2" /> <a href={inputFields.website} className='text-dark' target='_blank'>{inputFields.website}</a></div>:null}
						<div className='d-flex'>
							{inputFields.facebook_url?<div className='fs-16rem p-1 mb-1'><a href={inputFields.facebook_url} target='_blank'><img src="/assets/images/socialmedia/facebook.png" width="35px" /></a></div>:null}
							{inputFields.instagram_url?<div className='fs-16rem p-1 mb-1'><a href={inputFields.instagram_url} target='_blank'><img src="/assets/images/socialmedia/instagram.png" width="35px" /></a></div>:null}
							{inputFields.youtube_url?<div className='fs-16rem p-1 mb-1'><a href={inputFields.youtube_url} target='_blank'><img src="/assets/images/socialmedia/youtube.png" width="35px" /></a></div>:null}
							{inputFields.linkedin_url?<div className='fs-16rem p-1 mb-1'><a href={inputFields.linkedin_url} target='_blank'><img src="/assets/images/socialmedia/linkedin.png" width="35px" /></a></div>:null}
						</div>
						{inputFields.googlereview_url?<div className='mt-2 mb-3'>
							<p className='mb-2 text-secondary'>Your feedback will contribute to our continued growth and development.</p>
							<a href={inputFields.googlereview_url} target='_blank' className='rwt-secondary-btn text-end btn-md text-white btn btn-outline-none' ><FontAwesomeIcon icon={faThumbsUp} className="fs-20rem me-2" />  Review Us on Google</a>
						</div>:null}
					</div>
				</div>
				<div className='col-md-6'>
					<iframe src={inputFields.geolocation_url} width="100%" height="450" frameBorder="0" allowFullScreen="" style={{border: '0px'}}></iframe>
				</div>
			</div>
		</div>
	}

    useEffect(() => {
		async function fetchData() {
			setIsLoading(true);
			let apiData = await ajaxCall.getCall(`${config.api.generic}/public/account/row`, '', token);
            if(apiData && apiData.status) {
				dispatch({type: 'updateOrgContactInfo', payload: apiData.context.data[0]});
				setIsLoading(false);
				setDomainNotFound(false);
            } else {
				setIsLoading(false);
				setDomainNotFound(true);
			}
		}
		if(inputFields && Object.keys(inputFields).length === 0) {
			fetchData();	
		}
	}, []);

	console.log(inputFields)

	return (
		<div className="container">
			<div className="row">
				<div className="col">
					<div className="mt-3">
						<h3 className="fs-20rem text-center rwt-txt-dark-blue mb-0">Contact Information</h3>
						<div className='d-flex'>
							<Breadcrumb list={[{label: 'Home', url: `/catering/${orgInfo.domain}`}, {label: 'Contact Information', url: false}]} />
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-10 offset-md-1 ">
					<div className="py-3 mb-5">
						{(IsLoading)? <Loading /> : (DomainNotFound)? <Domain404 /> : <ShowDetails />}
					</div>
				</div>
			</div>
		</div>
	)
}

export default OrgContact;