// libs
import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
// components 
import Record404 from './record404';
import Loading from '../components/loading';
// config & functions 
// ...

function ListAllCategory({industryid, loading, isonlyveg, foodtype, setfoodtype, predata, appliedcategory, setappliedcategory, setshowallcategories}) {

    const selectCategory = (val) => {
        setappliedcategory(val)
        setshowallcategories(false)
    }

    const CategoryList = () => {
        // (industryid > 2) if industry apart from food category, show all by default in category list
        return (predata && Array.isArray(predata) && predata.length>0)? <div className="list-group list-group-flush">
            {isonlyveg || industryid > 2 || foodtype==="all"?<button type="button" className="fs-14rem list-group-item list-group-item-action" onClick={() => selectCategory('')}>All {(appliedcategory==="")?<FontAwesomeIcon icon={faCheck} className="rwt-txt-blue" />:''}</button>:null}
            {predata.map(ele => {
                return <button key={Math.random()} type="button" className="fs-14rem list-group-item list-group-item-action" onClick={() => selectCategory(ele.id)}>{ele.label} {(parseInt(appliedcategory)===parseInt(ele.id))?<FontAwesomeIcon icon={faCheck} className="rwt-txt-blue" />:''}</button>
            })}
        </div>:<div className="container">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <Record404 />
                </div>
            </div>
        </div>
    }

    const Options = () => {
        let sc = "All";
        predata.map((element) => { 
            if(parseInt(appliedcategory)===parseInt(element.id)) {
                sc = element.label;
            }
        })
        return isonlyveg?<div className="d-flex p-2">
                <div className="fs-14rem"><FontAwesomeIcon icon={faCheck} className="text-success" /> <strong className="text-success">Pure Veg <img src="/assets/images/pure-veg.jpg" style={{height: '20px'}} /></strong></div>
            </div>:<div className="d-flex py-2 px-3 fs-14rem">
            <div className="me-2">
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="foodType" value="all" id="allFood" defaultChecked={foodtype==='all'?true:false} onClick={e => setfoodtype(e.target.value)} />
                    <label className="form-check-label" htmlFor="allFood">All</label>
                </div>
            </div>
            <div className="me-2">
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="foodType" value="veg" id="vegFood" defaultChecked={foodtype==='veg'?true:false} onClick={e => setfoodtype(e.target.value)} />
                    <label className="form-check-label" htmlFor="vegFood">Veg</label>
                </div>
            </div>
            <div className="me-2">
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="foodType" value="nonveg" id="nonVegFood" defaultChecked={foodtype==='nonveg'?true:false} onClick={e => setfoodtype(e.target.value)} />
                    <label className="form-check-label" htmlFor="nonVegFood">Non-Veg</label>
                </div>
            </div>
            <div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="foodType" value="others" id="othersFood" defaultChecked={foodtype==='others'?true:false} onClick={e => setfoodtype(e.target.value)} />
                    <label className="form-check-label" htmlFor="othersFood">Others</label>
                </div>
            </div>
        </div>
    }

    return (
        <>
            <div className="modal fade show modal-xl" data-dismiss="modal" tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.6)'}}>
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Categories</h5>
                            <button type="button" className="btn-close" onClick={()=>setshowallcategories(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            {industryid > 2?null:<Options />}
                            {loading?<Loading />:<CategoryList />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ListAllCategory;