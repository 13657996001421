import React, { createContext, useReducer } from 'react'

const initialState = {
	userContactInfo: {
		clientId: "",
        firstName: "",
        lastName: "",
        phoneNo: "",
        email: "",
        address: "",
		stateId: "",
		cityId: "",
		pincode: "",
		statusId: 1
    },
	homePageInfo: {},
	orgContactInfo: {},
	editQuotation: {},
	helpers: {
		phoneNoVerified: false,
		isUserContactInfoAvailable: false,
		navLoading: true
	}
}
const ContextStore = createContext(initialState);

const ContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'updateUserContactInfo':
				return { ...state, userContactInfo: action.payload }
			case 'upadteReferenceName':
				let refName = state.editQuotation;
				refName.referenceName = action.payload;
				return { ...state, editQuotation: refName }
			case 'upadteGlobalStartDate':
				let startDate = state.editQuotation;
				startDate.startDate = action.payload;
				return { ...state, editQuotation: startDate }
			case 'upadteGlobalEndDate':
				let endDate = state.editQuotation;
				endDate.endDate = action.payload;
				return { ...state, editQuotation: endDate }
			case 'addNewMenuItem':
				let addItem = state.editQuotation;
				if(addItem.hasOwnProperty('menuItems')) {
					var aI = addItem.menuItems
				} else {
					var aI = addItem.menuItems = [];
				}
				aI.push(action.payload);
				addItem.menuItems = aI;
				return { ...state, editQuotation: addItem }
			case 'updateMenuItem':
				let updateItem = state.editQuotation;
				let uI = updateItem.menuItems
				uI[action.payload.objId].startDate = action.payload.startDate;
				uI[action.payload.objId].time = action.payload.time;
				uI[action.payload.objId].totalMembers = action.payload.totalMembers;
				uI[action.payload.objId].selectedItems = action.payload.selectedItems;
				updateItem.menuItems = uI;
				return { ...state, editQuotation: updateItem }
			case 'deleteMenuItem':
				let deleteItem = state.editQuotation;
				let dI = deleteItem.menuItems
				dI.splice(action.payload, 1);
				deleteItem.menuItems = dI;
				return { ...state, editQuotation: deleteItem }
			case 'editNewQuotation':
				return { ...state, editQuotation: action.payload }
			case 'resetEditQuotation': 
				let eq = {
					referenceName: "",
                    startDate: new Date(),
                    endDate: new Date(),
                    quotationId: '',
                    menuItems: [],
                    status: 1,
                }
				return { ...state, editQuotation: eq }
			case 'logout': 
				let resetAll = state;
				resetAll.userContactInfo = {
					clientId: "",
					firstName: "",
					lastName: "",
					phoneNo: "",
					email: "",
					address: "",
					stateId: "",
					cityId: "",
					pincode: "",
					statusId: 1
                };
				resetAll.editQuotation = {};
				resetAll.helpers = {
					phoneNoVerified: false,
					isUserContactInfoAvailable: false,
					navLoading: true
				};
				return resetAll;
			case 'updateOrgContactInfo':
				return { ...state, orgContactInfo: action.payload }
			case 'updateHomePageInfo':
				return { ...state, homePageInfo: action.payload }
			case 'updateHelpers':
				let helpers = state.helpers;
				if(action.payload && Array.isArray(action.payload) && action.payload.length > 0) {
					action.payload.map(d => {
						helpers[d.key] = d.value;
					});
				}
				return { ...state, helpers: helpers }
			default:
				throw new Error();
		}
	}, initialState)

	return <ContextStore.Provider value={{ state, dispatch }}>{children}</ContextStore.Provider>
}

export { ContextStore, ContextProvider }
