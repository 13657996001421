// libs
import React from 'react';
// components 
import Loading from './loading';
// config & functions 
// ...

function LoadingBox() {
    return (
        <div className="modal modal-dialog-centered fade show" data-dismiss="modal" tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.6)'}}>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-body">
						<div className='py-2'><Loading /></div>
					</div>
				</div>
			</div>
		</div>
    )
}
export default LoadingBox;
