// libs
import React, { useEffect, useRef, useState, useContext } from 'react';
import { ContextStore } from '../providers/context';
// components 
import Logo from './logo';
// css
// ...
// config & functions 
import utils from '../utils';

function Navbar({orgInfo, setloadmaincomponent, loadmaincomponent}) {
    // context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//

    const ref = useRef(null);
    const [Collapsing, setCollapsing] = useState(false);
    const [ShowNav, setShowNav] = useState(false);
    const [UserDropdown, setUserDropdown] = useState(false);
    const getHelpers = (globalState.hasOwnProperty('state') && globalState.state.hasOwnProperty('helpers')) ? globalState.state.helpers : null;
    const navLoading =  (getHelpers && getHelpers.hasOwnProperty('navLoading')) ? getHelpers.navLoading : false;
    const phoneNoVerified = (getHelpers && getHelpers.hasOwnProperty('phoneNoVerified')) ? getHelpers.phoneNoVerified : false;
    const isUserContactInfoAvailable = (getHelpers && getHelpers.hasOwnProperty('isUserContactInfoAvailable')) ? getHelpers.isUserContactInfoAvailable : false;
    const industryId = (orgInfo && orgInfo.hasOwnProperty('industryId')) ? orgInfo.industryId : '';
    
    const goNext = async (page) => {
		let uc = await utils.updateComponent(loadmaincomponent, {
			name: page,
			status: true
		});
		setloadmaincomponent(uc); // upate component
	}

    const logout = () => {
        utils.deleteCookie('phno');
        dispatch({type: 'logout'});
        goNext('HomePage');
    }

    const handleToggle = (val) => {
        setCollapsing(true);
        setTimeout(() => {
            setCollapsing(false);
            setShowNav(val);
        }, 50);
    }

    const handleClickOutside = (event) => {
        if (ref.current && ref.current.classList.contains('show')) {
            handleToggle(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div className="navbar navbar-expand-lg shadow-sm p-2 bg-body rounded sticky-top border-bottom">
            <div className="container">
                <Logo orgInfo={orgInfo} setloadmaincomponent={setloadmaincomponent} loadmaincomponent={loadmaincomponent} />
                <button type="button" className="navbar-toggler rwt-primary-btn" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={() => handleToggle(true)}>
                    <span className="">Menu</span>
                </button>
                <div className={`navbar-collapse ${Collapsing?`collapsing`:`collapse`} ${ShowNav?`show`:``} justify-content-end`} id="nav" ref={ref}>
                    <div className="navbar-nav ml-auto ">
                        <ul className="navbar-nav bg-white m-0 ml-lg-auto p-3 p-lg-0">
                            {!navLoading ? <>
                                <li className="d-inline d-lg-none">
                                    <button type="button" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" className="close float-end" onClick={() => handleToggle(false)}>&times;</button>
                                </li>
                                <li className="nav-item">
                                    <button type="button" onClick={() => goNext('HomePage')} className="text-start bg-white border-0 nav-link rwt-txt-dark-blue fw-bold">Home</button>
                                </li>
                                <li className="nav-item">
                                    <button type="button" onClick={() => goNext('BrowseProducts')} className="text-start bg-white border-0 nav-link rwt-txt-dark-blue fw-bold">Browse Menu</button>
                                </li>
                                {(parseInt(industryId) === 2 && phoneNoVerified)?<>
                                    <li className="nav-item">
                                        <button type="button" onClick={() => goNext('UserContact')} className="text-start bg-white border-0 nav-link rwt-txt-dark-blue fw-bold">User Info</button>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="text-start nav-link dropdown-toggle rwt-txt-dark-blue fw-bold" href="#" onClick={(e) => setUserDropdown(!UserDropdown)} id="userDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Quotation</a>
                                        <div className={`dropdown-menu-end dropdown-menu ${(UserDropdown)?` show`:``}`} aria-labelledby="userDropdown">
                                            <button type="button" className="dropdown-item" onClick={() => goNext(isUserContactInfoAvailable?'MenuSelection':'UserContact')}>Create</button>
                                            <button type="button" className="dropdown-item" onClick={() => goNext(isUserContactInfoAvailable?'QuotationList':'UserContact')}>View</button>
                                        </div>
                                    </li> 
                                </>:null}
                                <li className="nav-item">
                                    <button type="button" onClick={() => goNext('OrgContact')} className="text-start bg-white border-0 nav-link rwt-txt-dark-blue fw-bold">Connect Us</button>
                                </li>
                                {(parseInt(industryId) === 2)?<>
                                    <li className="nav-item">
                                        {phoneNoVerified?null:<button type="button" onClick={() => goNext('ValidatePhoneNo')} className="text-start bg-white border-0 nav-link rwt-txt-orange fw-bold">Verify Phone No to Continue</button>}
                                    </li>
                                    {phoneNoVerified?<li className="nav-item">
                                        <button type="button" onClick={logout} className="text-start bg-white border-0 nav-link rwt-txt-orange fw-bold">Logout</button>
                                    </li>:null}
                                </>:null}
                            </>:<li className="nav-item"><span className='nav-link rwt-txt-orange fw-bold'>Loading Navigation...</span></li>}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar;
