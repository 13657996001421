// libs
import React, {useEffect, useState} from 'react'
import { useParams } from "react-router-dom";
// components
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Loading from "../components/loading";
import Domain404 from "../components/domain404";
import ValidatePhoneNo from './validate-phoneno';
import UserContact from './user-contact';
import MenuSelection from './menu-selection';
import BrowseProducts from './browse-products/index';
import QuotationList from './quotation';
import ThankYouNote from './thankyou-note';
import OrgContact from './org-contact'
import HomePage from './home'
// css 
import "../assets/css/style.css";
import "../assets/css/core.css";
// config & functions
import config from "../config/index";
import ajaxCall from "../utils/ajaxcall";
import utils from '../utils';

function Dashboard() {
	const { domain } = useParams();
	const [OrgInfo, setOrgInfo] = useState({});
	const [Token, setToken] = useState(null);
	const [IsLoading, setIsLoading] = useState(true);
	const [DomainNotFound, setDomainNotFound] = useState(false);
	const [LoadMainComponent, setLoadMainComponent] = useState([{
			name: 'HomePage',
			component: HomePage, 
			status: true,
			industry: [1,2,3]
		},{	
			name: 'ValidatePhoneNo',
			component: ValidatePhoneNo, 
			status: false,
			industry: [2]
		},{	
			name: 'MenuSelection',
			component: MenuSelection, 
			status: false,
			industry: [2]
		},{	
			name: 'UserContact',
			component: UserContact, 
			status: false,
			industry: [2]
		}, {
			name: 'BrowseProducts',
			component: BrowseProducts, 
			status: false,
			industry: [1,2,3]
		}, {
			name: 'QuotationList',
			component: QuotationList, 
			status: false,
			industry: [2]
		}, {
			name: 'OrgContact',
			component: OrgContact, 
			status: false,
			industry: [1,2,3]
		}, {
			name: 'ThankYouNote',
			component: ThankYouNote, 
			status: false,
			industry: [2]
		}
	]);
	
	useEffect(() => {
		async function fetchData() {
			let apiData = await ajaxCall.postCall(`${config.api.generic}/account/validate-domain`, {
                domain: domain
            });
            if(apiData && apiData.status && apiData.context.hasOwnProperty('data') && apiData.context.data.length > 0) {
                const tokenVal = await utils.decodeJWT((apiData.context.data[0]));
				if(tokenVal && tokenVal.hasOwnProperty('data')) {
					const data = tokenVal.data;
					let getOrgName = (data.name) ? data.name : null;
					let getLogo = (data.logo) ? data.logo : null;
					let industryId = (data.industryId) ? data.industryId : null;
					let foodTypeId = (data.foodTypeId) ? data.foodTypeId : null;
					setOrgInfo({
						name: getOrgName,
						logo: getLogo,
						domain: domain,
						industryId: industryId,
						foodTypeId: foodTypeId
					});
					setToken(apiData.context.data[0]);
					setIsLoading(false);
				} else {
					setIsLoading(false);
					setDomainNotFound(true);	
				}
            } else {
				setIsLoading(false);
				setDomainNotFound(true);
			}
		}
		fetchData();
	}, []);

	if(IsLoading) {
		return (<Loading />)
	} else if (DomainNotFound) {
		return (<Domain404 />)
	} else {
		return (
			<>
				<Navbar orgInfo={OrgInfo} setloadmaincomponent={setLoadMainComponent} loadmaincomponent={LoadMainComponent} />
				{LoadMainComponent.map((data, i) => {
					if(data.status && data.industry.includes(parseInt(OrgInfo.industryId))) {
						return <data.component 
							key={Math.random()}  
							setloadmaincomponent={setLoadMainComponent} 
							loadmaincomponent={LoadMainComponent} 
							token={Token} 
							orgInfo={OrgInfo}
						/>
					}
				})}
			</>
		)
	}
}

export default Dashboard;